import(/* webpackMode: "eager", webpackExports: ["Hydrate","useHydrate"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/Hydrate.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/isRestoring.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["QueryClientProvider","defaultContext","useQueryClient"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/QueryClientProvider.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/QueryErrorResetBoundary.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["unstable_batchedUpdates"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/reactBatchedUpdates.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useInfiniteQuery.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useIsFetching.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useIsMutating.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useMutation.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useQueries.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useQuery.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-h5-audio-player/lib/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../assets/fonts/MuseoSans-100.woff\",\"weight\":\"100\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/MuseoSans-300.woff\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/MuseoSans-500.woff\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/MuseoSans-700.woff\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/MuseoSans-900.woff\",\"weight\":\"900\",\"style\":\"normal\"}],\"variable\":\"--museo-font\"}],\"variableName\":\"museo_sans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../assets/fonts/Endemic_Museo.woff\",\"style\":\"normal\"}],\"variable\":\"--endemic-museo\"}],\"variableName\":\"endemic_museo\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(components)/Application/Login/SuccessfulLoginPopup/SuccessfulLoginPopup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(components)/Application/ServiceMessageBanner/ServiceMessageBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(components)/Basic/CookieBanner/CookieBanner.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(components)/Layout/Footer/Footer.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(components)/Layout/Header/HeaderContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(components)/Layout/Header/HeaderContentWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(components)/ThirdParty/GoogleAnalytics.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/coinbase.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/metamask.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/social-link-discord.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/social-link-instagram.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/social-link-telegram.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/social-link-terms-of-use.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/social-link-twitter.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/themed/night/logo-v.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/walletConnect.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/shared/providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/web3/connectors/coinbase.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/web3/connectors/metaMask.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/web3/connectors/walletConnect.ts");
