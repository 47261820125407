import { ImagePlaceholder } from "@/app/(components)/Basic/Image/types";
import { Blockchain } from "@/shared/config/blockchain/types";
import { Follow } from "../follows/types";
import { PostCommentLikeDto, PostLikeDto } from "../likes/feed/types";
import { NftCommentLikeDto, NftLikeDto } from "../likes/nft/types";
import { ArtOrderOffer } from "../art-order/types";
export interface ShortUser {
  id: string;
  email?: string;
  address?: string;
  displayName?: string;
  blockchain?: Blockchain[];
  imageUrl?: string;
  imagePlaceholder?: ImagePlaceholder;
  headerImageUrl?: string;
  headerImagePlaceholder?: ImagePlaceholder;
  fullName?: string;
  description?: string;
  isFeatured?: string;
  isSuperstar?: string;
  isCollector?: string;
  slug?: string;
  hideProfile: boolean;
  level?: number;
  points?: number;
}
export interface User extends ShortUser {
  homeAddress?: string;
  twitter?: string;
  instagram?: string;
  facebook?: string;
  website?: string;
  discord?: string;
  telegram?: string;
  isEmailVerified: boolean;
  description?: string;
  endemicIdentityTokenId?: number;
  receiveTradeNotifications?: boolean;
  receiveFollowedNotification?: boolean;
  receiveTipReceivedNotification?: boolean;
  receiveNftReadyToMintNotification?: boolean;
  receiveNftDeniedNotification?: boolean;
  receiveNftNotification?: boolean;
  receivePostNotification?: boolean;
  emailVerificationMailSentOn?: any;
  lastNotificationReadTimestamp?: any;
  following?: Follow[];
  followers?: Follow[];
  nftLikes?: NftLikeDto[];
  nftCommentLikes: NftCommentLikeDto[];
  postLikes?: PostLikeDto[];
  postCommentLikes: PostCommentLikeDto[];
  role?: UserRoles;
  artOffers?: ArtOrderOffer[];
  completedQuests?: {
    telegram?: boolean;
    twitter?: boolean;
  };
  referralCode: string;
  isVerified: boolean;
  avgRating?: number;
}

export interface UserUpdate extends ShortUser {
  twitter?: string;
  instagram?: string;
  facebook?: string;
  website?: string;
  discord?: string;
  telegram?: string;
  homeAddress?: string;
  clothingSize?: string;
  isEmailVerified?: boolean;
  emailVerificationMailSentOn?: any;
  receiveTradeNotifications?: boolean;
  receiveNftReadyToMintNotification?: boolean;
  receiveNftDeniedNotification?: boolean;
  receiveFollowedNotification?: boolean;
  receiveTipReceivedNotification?: boolean;
  receiveNftNotifications?: boolean;
  receivePostNotifications?: boolean;
}

export interface ForumUser extends ShortUser {
  forumPostsCount?: number;
}

export interface GetFollowsQuery {
  userId: string;
  page?: number;
  pageSize?: number;
}

export enum SortType {
  RANDOM = "RANDOM",
  NEWLY_JOINED = "NEWLY_JOINED",
  MOST_FOLLOWERS = "MOST_FOLLOWERS",
}

export enum UserRoles {
  USER = "USER",
  ARTIST = "ARTIST",
}
