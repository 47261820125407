export enum Theme {
  Light = "light",
  Night = "night",
  Endemic = "endemic",
}

export enum Colors {
  Yellow = "yellow",
  Green = "green",
  Red = "red",
}

export enum Sizes {
  SM = "640px",
  MD = "768px",
  LG = "1024px",
  XL = "1280px",
  XXL = "1536px",
}

export enum SizesValues {
  SM = 640,
  MD = 768,
  LG = 1024,
  XL = 1280,
  XXL = 1536,
}

export enum TEXT_COLOR_VARIANTS {
  green = "text-green",
  red = "text-red",
  yellow = "text-yellow",
  primary = "text-primary",
  secondary = "text-secondary",
}

export enum HexColors {
  Yellow = "#FBB040",
  Green = "#00A79D",
  Red = "#D60A51",
}

export enum MasonryColumns {
  Single = 1,
  Double = 2,
  Triple = 3,
}

export const HEADER_ANIMATION_OFFSET_Y = 50;

//Add another currencies
export enum CollectionSliderView {
  Author,
  Collection,
}

export enum CryptoCurrencies {
  Ether = "ethereum",
  Matic = "matic",
  Weth = "wrapped-ether",
}

export enum CryptoCurrencyID {
  ETH = "ethereum",
  WETH = "wrapped-ethereum",
  MATIC = "matic-network",
}

export enum TimeUnit {
  DAYS = "days",
  HOURS = "hours",
  SECONDS = "seconds",
}

export const ERC20Currencies = [CryptoCurrencyID.MATIC, CryptoCurrencyID.WETH];

export const DEFAULT_PAGE_SIZE = 8;
export const DEFAULT_CARDS_PAGE_SIZE = 9;
export const LARGER_PAGE_SIZE = 12;
export const DEFAULT_COLLECTION_SLIDER_LENGTH = 5;
export const DEFAULT_EXPLORE_PAGE_SIZE = 8;
export const BAD_REQUEST_ERROR_STATUS_CODE = 400;

export const DEFAULT_NETWORK_ERROR_MESSAGE =
  "We are experiencing an issue loading your request - our engineering team has been notified.";

export const DEFAULT_METAMASK_ERROR_MESSAGE =
  "There was an issue processing your transaction - our engineering team has been notified.";

import metamaskConnector from "@/web3/connectors/metaMask";
import coinbaseConnector from "@/web3/connectors/coinbase";
import walletConnectConnector from "@/web3/connectors/walletConnect";
import MetamaskIcon from "@/assets/icons/metamask.svg";
import WalletConnectIcon from "@/assets/icons/walletConnect.svg";
import CoinbaseIcon from "@/assets/icons/coinbase.svg";
import { ZeroAddress } from "ethers";
import { Blockchain } from "./config/blockchain/types";

export enum ConnectorType {
  Metamask = "metamask",
  Coinbase = "coinbase",
  WalletConnect = "walletConnect",
}

export const CONNECTORS = {
  [ConnectorType.Metamask]: metamaskConnector,
  [ConnectorType.Coinbase]: coinbaseConnector,
  [ConnectorType.WalletConnect]: walletConnectConnector,
};

export const CONNECTOR_ICONS = {
  [ConnectorType.Metamask]: <MetamaskIcon className="w-5 h-5" />,
  [ConnectorType.Coinbase]: <CoinbaseIcon className="w-5 h-5" />,
  [ConnectorType.WalletConnect]: <WalletConnectIcon className="w-5 h-5" />,
};

export const CONNECTOR_LABELS = {
  [ConnectorType.Metamask]: "Metamask",
  [ConnectorType.Coinbase]: "Coinbase",
  [ConnectorType.WalletConnect]: "Wallet Connect",
};

export enum FormTooltip {
  FullName = "Enter your full name, up to 30 characters, to unlock additional features on your profile. A full name is required for profile activation.",
  UserName = "Enter your username, up to 30 characters, to unlock additional features on your profile. Username is required for profile activation.",
  Email = "Providing a valid email address is essential for unlocking additional features on your profile and potentially receiving notifications.",
  Description = "Introduce yourself to the community in 500 characters or fewer. A brief bio helps others get to know you better.",
  HomeAddress = "Provide your home address to become eligible for merchandise deliveries in case you win any of our giveaways or promotions.",
  Country = "Provide your country of residence for merchandise deliveries in case you win any of our giveaways or promotions.",
  ClothingSize = "Select your clothing size to ensure that any merchandise you win in our giveaways or promotions is a perfect fit for you.",
  Xtwitter = "Share your X account with other members to connect with like-minded individuals and expand your network on our platform.",
  Instagram = "Share your Instagram profile with other member to showcase your visual content and better engage with our community.",
  Facebook = "Link your Facebook account to easily connect with friends and like-minded individuals.",
  Discord = "Connect your Discord username to allow other members to contact you.",
  Telegram = "Connect your Telegram to allow other members to contact you.",
  Website = "Share the URL of your personal or professional website to provide additional context about your interests and expertise.",
  Portfolio = "Link your portfolio so the others can see your art on your Endemic profile.",
  Wallet = "Connecting a wallet is necessary to participate in auctions, bid for artwork and collections and/or receive payments.",
}

export const MaticAddress = "0x0000000000000000000000000000000000001010";

export const NativeCurrencies = [ZeroAddress, MaticAddress];

export const NativeCurrenciesPerChain: { [blockchain: string]: string } = {
  [Blockchain.ETHEREUM]: ZeroAddress,
  [Blockchain.ARBITRUM]: ZeroAddress,
  [Blockchain.POLYGON]: MaticAddress,
};

export const OFFERS_DROPDOWN_DATE_PICKER_OPTIONS = [
  {
    label: "7d",
    value: "7",
    timeUnit: TimeUnit.DAYS,
  },
  {
    label: "3d",
    value: "3",
    timeUnit: TimeUnit.DAYS,
  },
  {
    label: "1d",
    value: "1",
    timeUnit: TimeUnit.DAYS,
  },
  {
    label: "12h",
    value: "12",
    timeUnit: TimeUnit.HOURS,
  },
  {
    label: "Custom",
    value: "custom",
  },
];

export const BASE_TWITTER_RE_TWEET_URL = "https://twitter.com/intent/tweet";

export const DEFAULT_NFT_SIZE = 580;

export const DEFAULT_SMALL_NFT_HEIGHT = 110;
export const DEFAULT_SMALL_NFT_WIDTH = 150;

export const marketplaceOptions = [
  {
    value: "selectAll",
    label: "Select all",
  },
  {
    value: "endemic",
    label: "Endemic",
  },
  {
    value: "foundation",
    label: "Foundation",
  },
  {
    value: "known_origin",
    label: "Known origin",
  },
  {
    value: "super_rare",
    label: "Super rare",
  },
];
