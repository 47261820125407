"use client";
import { useState } from "react";
import { marketplaceOptions } from "@/shared/constants";
import { useSearchParams, useRouter, usePathname } from "next/navigation";
import FilterIcon from "@/assets/icons/filter.svg";
import { twMerge } from "tailwind-merge";
import { MultipleSelectDropdownInput } from "@/app/(components)/Basic/MultiSelectDropdownInput";
import OfferIcon from "@/assets/icons/offer.svg";
import { OptionPicker } from "@/app/(components)/Basic/DropdownInputBase/types";

export interface Props {
  containerClassName?: string;
}

const MARKETPLACES = "marketplaces";

const MarketplaceFilter = ({ containerClassName }: Props) => {
  const searchParams = useSearchParams();
  const router = useRouter();
  const pathname = usePathname();

  const marketplacesParams = searchParams.get(MARKETPLACES);

  const marketplacesArray = marketplacesParams
    ? marketplacesParams.split(",")
    : [];

  const [marketplaces, setMarketplaces] = useState(marketplacesArray);

  const onFilterSearch = () => {
    const params = new URLSearchParams(searchParams.toString());

    if (!!marketplaces.length) {
      const marketplacesString = marketplaces
        .filter((m) => m !== "selectAll")
        .join(",");

      params.set(MARKETPLACES, marketplacesString);
    } else {
      params.delete(MARKETPLACES);
    }
    router.push(pathname + "?" + params.toString());
  };

  return (
    <div className={twMerge("flex items-center gap-x-3", containerClassName)}>
      <MultipleSelectDropdownInput
        startIcon={<OfferIcon />}
        selectedOption={marketplaces}
        setSelectedOption={setMarketplaces}
        options={marketplaceOptions}
        childrenClassName="w-[350px]"
        label={
          <>
            <span className="font-light mr-1">Marketplace:</span>
            {marketplaces.length > 1
              ? `${marketplaces.filter((opt: string) => opt !== "selectAll").length} selected`
              : marketplaces[0]}
          </>
        }
        optionPicker={OptionPicker.CHECKBOX}
        className="w-[350px]"
      />
      <FilterIcon onClick={onFilterSearch} className="w-5 h-5 cursor-pointer" />
    </div>
  );
};

export default MarketplaceFilter;
