"use client";
import { SearchCategory } from "@/api/search/types";
import { PropsWithChildren } from "react";
import { MenuItem, Navigation } from "../../Layout/Navigation";
import SearchMenuItem from "./SearchMenuItem";
import { usePathname } from "next/navigation";
import { twMerge } from "tailwind-merge";
import { MarketplaceFilter } from "../Marketplace/MarketplaceFilter";

const menuItems: MenuItem[] = [
  {
    name: <SearchMenuItem id={SearchCategory.Collections} name="Collections" />,
    href: `/search/collections`,
  },
  {
    name: <SearchMenuItem id={SearchCategory.Artists} name="Artists" />,
    href: `/search/artists`,
  },
  {
    name: <SearchMenuItem id={SearchCategory.Artwork} name="Artwork" />,
    href: `/search/artwork`,
  },
];

function SearchPageWrapper({ children }: PropsWithChildren) {
  const pathname = usePathname();

  const hideMarketplaceFilter = [
    "/search/artists",
    "/search/collections",
  ].includes(pathname);

  return (
    <>
      <div className="flex items-center justify-center">
        <Navigation
          menuItems={menuItems}
          shouldHideActiveIndicatorOnScroll={false}
          minimizeOnScroll={true}
          className="lg:-bottom-2"
          preserveSearchQuery
        />
      </div>
      <div
        className={twMerge(
          "w-full flex justify-end mb-10 pr-4 lg:pr-0",
          hideMarketplaceFilter && "hidden",
        )}
      >
        <MarketplaceFilter />
      </div>
      {children}
    </>
  );
}

export default SearchPageWrapper;
