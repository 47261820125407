"use client";
import React from "react";
import { Theme } from "@/shared/constants";
import NotificationButton from "@/app/(components)/Application/Notifications/NotificationButton";
import Navigation from "../Navigation/Navigation";
import MobileNavigation from "../MobileSidebar/MobileSidebar";
import Link from "next/link";
import ArtworkSubItemImage from "@/assets/graphics/artwork.svg";
import ArtistsSubItemImage from "@/assets/graphics/artists.svg";
import CollectionsSubItemImage from "@/assets/graphics/collections.svg";
// import RankingsSubItemImage from "@/assets/graphics/rankings.svg";
import ActivitySubItemImage from "@/assets/graphics/activity.svg";
import CollectivesSubItemImage from "@/assets/graphics/collectives.svg";
import AllPostsSubItemImage from "@/assets/graphics/all-posts.svg";
import NewsSubItemImage from "@/assets/graphics/news.svg";
import KnowHowSubItemImage from "@/assets/graphics/know-how.svg";
import ArtAndArtistsSubItemImage from "@/assets/graphics/art-and-artists.svg";
import ViewApprovalsSubItemImage from "@/assets/graphics/endemic-green2.svg";
import CreateArtworkSubItemImage from "@/assets/graphics/endemic-red2.svg";
import CreateCollectionSubItemImage from "@/assets/graphics/endemic-yellow2.svg";
import config from "@shared/config/client";

import Logo from "../../Basic/Logo/Logo";
import { Button } from "../../Basic/Button";
import { ThemedIcon } from "../../Basic/Icon";
import { UserRoles } from "@/api/users/types";
import UserDropdown from "../../Application/User/Dropdown/UserDropdown";
import { SearchInput } from "../../Application/Search";
import { POST_CATEGORY_SLUG, PostCategoryType } from "@/api/contentful/types";
import { PORTAL_ROUTE, getCategoryRoute } from "@/app/media/constants";
import useMe from "@/shared/hooks/useMe";
import { useWeb3State } from "@/web3/context";
import { MenuItem } from "../Navigation";
import { useQuery } from "@tanstack/react-query";
import lastSeenArtOrderApi from "@/api/lastSeenArtOrder/client";
import NotificationIndicator from "../../Application/Notifications/NotificationIndicator";

export interface Props {
  theme?: Theme;
}

export function HeaderContent({ theme }: Props) {
  const { me } = useMe();

  const { network } = useWeb3State();
  const notProductionReadyMenuItems = ["/art-orders"];

  const { data: unseenOrdersExist } = useQuery({
    queryKey: ["unseen-orders-exist", me?.id],
    queryFn: lastSeenArtOrderApi.checkIfUnseenOrdersExist,
    enabled:
      me?.role === UserRoles.ARTIST &&
      (config.webEnv !== "production" ||
        !notProductionReadyMenuItems.includes("/art-orders")),
  });

  const shouldShowCreateMenu =
    !!me &&
    me.role === UserRoles.ARTIST &&
    me.isVerified &&
    me.blockchain?.includes(network);

  const menuItems: MenuItem[] = [
    {
      name: "Explore",
      href: "#",
      subMenu: [
        {
          name: "Artwork",
          title: "Artwork",
          description: "Explore hundreds of unique artworks on our platform.",
          href: "/artwork",
          image: <ArtworkSubItemImage className="h-full" />,
          iconNameOnMobile: "artwork-mobile",
        },
        {
          name: "Artists",
          title: "Artists",
          description:
            "Our hand-picked artists are the heartbeat of Endemic marketplace.",
          href: "/artists",
          image: <ArtistsSubItemImage className="h-full" />,
          iconNameOnMobile: "artists-mobile",
        },
        {
          name: "Collections",
          title: "Collections",
          description: "Discover rare and unique collections.",
          href: "/collections",
          image: <CollectionsSubItemImage className="h-full" />,
          iconNameOnMobile: "collections-mobile",
        },
        {
          name: "Activity",
          title: "Activity",
          description: "Check out what the artists you follow have been up to.",
          href: "/activities",
          image: <ActivitySubItemImage className="h-full" />,
          iconNameOnMobile: "activity-mobile",
        },
        {
          name: "Collectives",
          title: "Collectives",
          description: "Discover groups of artists.",
          href: "/collectives",
          image: <CollectivesSubItemImage className="h-full" />,
          iconNameOnMobile: "collectives-mobile",
        },
        // {
        //   name: "Rankings",
        //   title: "Rankings",
        //   description: "Stay updated on the highest-rated art and artists.",
        //   href: "/rankings",
        //   image: <RankingsSubItemImage className="h-full -scale-x-100" />,
        //   iconNameOnMobile: "rankings-mobile",
        // },
      ],
    },
    {
      name: (
        <div className="lg:text-center relative">
          {"Art Orders"}
          {unseenOrdersExist && (
            <NotificationIndicator
              size="small"
              className="absolute lg:right-[-8px] right-[140px] top-0"
            />
          )}
        </div>
      ),
      href: "/art-orders",
    },
    {
      name: "Art feed",
      href: "/feed",
    },
    {
      name: "Forum",
      href: "/forum",
    },
    {
      name: "Media",
      href: "#",
      subMenu: [
        {
          name: "All posts",
          title: "All posts",
          description: "Check out posts spanning all categories.",
          href: PORTAL_ROUTE,
          image: <AllPostsSubItemImage className="h-full" />,
          iconNameOnMobile: "all-posts-mobile",
        },
        {
          name: PostCategoryType.News,
          title: "News",

          description: "Dive into the latest happenings in the NFT world.",
          href: getCategoryRoute(POST_CATEGORY_SLUG[PostCategoryType.News]),
          image: <NewsSubItemImage className="h-full" />,
          iconNameOnMobile: "news-mobile",
        },
        // {
        //   name: PostCategoryType.Interview,
        //   title: "Interview",
        //   description: "Insightful talks with industry leaders.",
        //   href: getCategoryRoute(POST_CATEGORY_SLUG[PostCategoryType.Interview]),
        //   image: <CollectionsSubItemImage className="h-full" />,
        // },
        {
          name: PostCategoryType.ArtAndArtists,
          title: "Art & Artists",
          description:
            "Uncover the creative side of art and artists in our digital real.",
          href: getCategoryRoute(
            POST_CATEGORY_SLUG[PostCategoryType.ArtAndArtists],
          ),
          image: <ArtAndArtistsSubItemImage className="h-full" />,
          iconNameOnMobile: "art-and-artists-mobile",
        },
        {
          name: PostCategoryType.KnowHow,
          title: "Know-how",
          description:
            "Discover valuable insights and knowledge in the digital landscape.",
          href: getCategoryRoute(POST_CATEGORY_SLUG[PostCategoryType.KnowHow]),
          image: <KnowHowSubItemImage className="h-full" />,
          iconNameOnMobile: "know-how-mobile",
        },
      ],
    },
    ...(shouldShowCreateMenu
      ? [
          {
            name: "Create",
            href: "/create/collection",
            subMenu: [
              {
                name: "View artwork approvals",
                title: "View artwork approvals",
                description:
                  "Preview artwork approvals completed by the Endemic curatorial team. Mint the approved artwork on the blockchain.",
                href: "/create/approvals",
                image: (
                  <ViewApprovalsSubItemImage className="h-10 lg:h-full ml-1 lg:ml-0" />
                ),
              },
              {
                name: "Create new artwork",
                title: "Create new artwork",
                description:
                  "Prepare your artwork and send it to the Endemic curatorial team for review. Once approved, the artwork can be minted on the blockchain.",
                href: "/create/nft",
                image: (
                  <CreateArtworkSubItemImage className="h-10 lg:h-full ml-1 lg:ml-0" />
                ),
              },
              {
                name: "Create new collection",
                title: "Create new collection",
                description:
                  "Deploy an ERC-721 smart contract that you can mint your artwork to.",
                href: "/create/collection",
                image: (
                  <CreateCollectionSubItemImage className="h-10 lg:h-full ml-1 lg:ml-0" />
                ),
              },
            ],
          },
        ]
      : []),
    ...(config.tokenPageUrl
      ? [
          {
            name: "Token",
            href: config.tokenPageUrl,
            className: "text-endemic gradient font-black text-[20px]",
            target: "_blank",
          },
        ]
      : []),
  ];

  const menuItemsCalculated = menuItems.filter(
    (item) =>
      config.webEnv !== "production" ||
      !notProductionReadyMenuItems.includes(item.href),
  );
  return (
    <div>
      {/* <div className="flex justify-end items-center h-10 border-b gap-6 xl:px-6 2xl:px-12 px-6">
          <BlockchainSwitcher />
          <ThemeSelector theme={theme} />
        </div> */}
      <div className="flex items-center justify-between xl:px-12 lg:px-6 px-4 py-3">
        <div className="flex">
          <div className="flex items-center lg:w-auto  w-full">
            <Link href="/" className="relative">
              <Logo alt="Logo" className="w-32 h-5 lg:hidden" />
              <ThemedIcon
                alt="Logo"
                iconName="endy"
                className={"hidden lg:flex lg:h-9 w-10 h-5 xl:mr-10 lg:mr-6"}
              />
            </Link>
          </div>
          <Navigation
            menuItems={menuItemsCalculated}
            shouldHideActiveIndicatorOnScroll={true}
          />
        </div>

        <div className="lg:hidden lg:h-auto flex items-center gap-x-[52px]  ml-auto h-10">
          {me ? (
            <div className="flex gap-3 items-center">
              <UserDropdown user={me} small />
              <NotificationButton small />
            </div>
          ) : (
            <Button
              className="whitespace-nowrap h-8 mt-1 text-base"
              href={"/login"}
            >
              {"Log in"}
            </Button>
          )}
          <SearchInput small className="right-11" />
          <MobileNavigation theme={theme} menuItems={menuItemsCalculated} />
        </div>

        <div className="hidden lg:flex lg:gap-x-2 xl:gap-x-5 lg:justify-end items-center">
          {me ? (
            <UserDropdown user={me} />
          ) : (
            <Button
              className="md:night:hover:bg-yellow"
              href={"/login"}
              startIcon={<ThemedIcon className="w-6 h-6" iconName="endy" />}
            >
              {"Log in"}
            </Button>
          )}
          {me && <NotificationButton />}
          <span className="w-11 h-11 hidden lg:flex relative">
            <SearchInput />
          </span>
        </div>
      </div>
    </div>
  );
}

export default HeaderContent;
